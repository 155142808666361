
<template>
  <div
    v-if="is_active && !is_loading"
    class="button button-active body-1"
    :class="{ 'hot-pink': is_hot }"
    :style="{ height: height, borderRadius: radius }"
    @click="$emit('clicked')"
  >
    {{ text }}
  </div>
  <div
    v-if="!is_active && !is_loading"
    class="button button-inactive body-1"
    :style="{ height: height, borderRadius: radius }"
  >
    {{ text }}
  </div>
  <div
    v-if="is_loading"
    class="button button-inactive"
    :style="{ height: height, borderRadius: radius }"
  >
    <div class="load-3">
      <div class="square"></div>
      <div class="square"></div>
      <div class="square"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicButton",
  props: {
    text: {
      type: String,
      default: "Button text",
    },
    height: {
      type: String,
      default: "60px",
    },
    radius: {
      type: String,
      default: "15px",
    },
    is_active: {
      type: Boolean,
      default: true,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    is_hot: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clicked"],
};
</script>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 15px;
}

.button-active {
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.button-active:hover {
  cursor: pointer;
  background: var(--secondary-bg-color);
  border: 1px solid var(--secondary-bg-color);
}

.button-inactive {
  background: var(--main-bg-color);
  color: var(--secondary-bg-color);
  border: 1px solid var(--secondary-bg-color);
}

.hot-pink {
  color: #fcfcfc !important;
  font-weight: bold;
  background-color: #f1035e !important;
  border: 1px #f1035e !important;
  box-shadow: 0px 0px 10px rgba(240, 0, 92, 0.3),
    0px 15px 30px rgba(250, 0, 83, 0.1) !important;
}

.square {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #cccccc;
}

.load-3 .square:nth-last-child(1) {
  animation: loading 1.2s 0s ease-in-out infinite;
}
.load-3 .square:nth-last-child(2) {
  animation: loading 1.2s 0.4s ease-in-out infinite;
}
.load-3 .square:nth-last-child(3) {
  animation: loading 1.2s 0.8s ease-in-out infinite;
}

@keyframes loading {
  0 {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
