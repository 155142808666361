
<template>
  <div class="container-titlebar">
    <div class="title-bg dead-center">
      <div class="h6" style="padding-bottom: 1em">
        {{ title }}
      </div>
      <div class="body-1" style="color: #666666">{{ description }}</div>
    </div>
    <Spacer />
    <CloseButton @clicked="gotoHome()" />
  </div>
</template>

<script>
import CloseButton from "../components/CloseButton.vue";
import Spacer from "../components/Spacer.vue";

export default {
  name: "TemplateTitleBar",
  components: {
    CloseButton,
    Spacer,
  },
  props: {
    title: String,
    description: String,
  },
  methods: {
    gotoHome() {
      window.location.href = "../";
    },
  },
};
</script>

<style scoped>
.container-titlebar {
  display: flex;
  align-items: center;
}
.title-bg {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: left;
  padding-left: 1em;
  padding-right: 5em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-radius: 15px;
  background: var(--main-bg-color);
  border-left: 2px solid var(--secondary-bg-color);
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.02),
    -30px 0px 30px rgba(0, 0, 0, 0.04);
}
</style>
