<template>
  <div class="notification body-2">
    <div class="dead-center">
      <img :src="icon_warning" style="width: 28px; height: 28px" />
    </div>
    <div>{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: ["message"],
  data() {
    return {
      icon_warning: require("../assets/warning.svg"),
    };
  },
};
</script>

<style scoped>
.notification {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-content: center; */
  /* text-align: center; */
  gap: 20px;
  padding: 20px;
  background: #fff9de;
  border: 1px solid #ffef91;
  border-radius: 15px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.01),
    0px 15px 30px rgba(0, 0, 0, 0.04);
}
</style>