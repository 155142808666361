
<template>
  <div class="large-preview">
    <img :src="src" class="themed-svg-icon img-adjustments" id="svg-image" />
  </div>
</template>

<script>
export default {
  name: "LargePreview",
  components: {},
  props: {
    src: String,
  },
};
</script>

<style scoped>
.img-adjustments {
  filter: var(--brightness-filter);
}
.large-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: var(--primary-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.large-preview img {
  max-width: 100%;
  max-height: 100%;
}
</style>
