
<template>
  <div class="close dead-center" @click="$emit('clicked')">
    <img
      :src="icon_close"
      class="themed-svg-icon"
      style="width: 1em; height: 1em; margin: auto"
    />
  </div>
</template>

<script>
export default {
  name: "CloseButton",
  data() {
    return {
      icon_close: require("../assets/close.svg"),
    };
  },
  emits: ["clicked"],
};
</script>

<style scoped>
.close {
  width: 2.5em;
  height: 2.5em;
  background-color: var(--main-bg-color);
  border-radius: 1.25em;
  box-shadow: var(--first-shadow), var(--second-shadow);
  border-bottom: 2px solid var(--secondary-bg-color);
}

.close:hover {
  cursor: pointer;
  background-color: var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}
</style>
