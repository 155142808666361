<template>
  <div class="container-all">
    <div class="container">
      <TemplateTitleBar
        :title="formatTemplateName()"
        :description="template_description"
      />
      <div class="container-content">
        <div class="container-left">
          <BasicButton
            v-if="templateHasInputFile()"
            text="Privacy Policy"
            height="40px"
            :is_hot="true"
            @clicked="gotoPrivacyPolicy"
          />
          <div class="parameters-panel">
            <Notification
              v-if="experimental && !preview"
              message="This template is experimental and may not work as expected."
            />
            <Notification
              v-if="preview"
              message="This template is only a preview! Please come back later."
            />
            <div v-for="(parameter, index) in parameters" :key="index">
              <HorizontalLine v-if="index > 0" style="margin: 0 0" />
              <div
                v-if="parameter.type === 'file'"
                class="container-parameter"
                style="flex-direction: column !important"
              >
                <div>
                  <div class="h6">{{ parameter.nice_name }}</div>
                  <div class="body-1" style="color: #666666">
                    {{ parameter.description }}
                  </div>
                </div>
                <div style="text-align: left">
                  <input
                    v-model="parameter.value"
                    :name="parameter.name"
                    :type="parameter.type"
                    :value="parameter.value"
                  />
                </div>
              </div>
              <div
                v-if="parameter.type === 'checkbox'"
                class="container-parameter"
              >
                <div>
                  <div class="h6">{{ parameter.nice_name }}</div>
                  <div class="body-1" style="color: #666666">
                    {{ parameter.description }}
                  </div>
                </div>
                <div style="text-align: right">
                  <input
                    v-model="parameter.value"
                    class="checkbox"
                    :name="parameter.name"
                    :type="parameter.type"
                    :value="parameter.value"
                  />
                </div>
              </div>
              <div
                v-if="parameter.type === 'number'"
                class="container-parameter"
              >
                <div>
                  <div class="h6">{{ parameter.nice_name }}</div>
                  <div class="body-1" style="color: #666666">
                    {{ parameter.description }}
                  </div>
                </div>
                <div style="text-align: right">
                  <div class="h6" style="margin-bottom: 10px">
                    {{ parameter.value }} {{ parameter.unit }}
                  </div>
                  <input
                    v-model="parameter.value"
                    :name="parameter.name"
                    :type="parameter.type"
                    :value="parameter.value"
                    :min="parameter.minimum"
                    :max="parameter.maximum"
                    :step="parameter.step"
                    :class="{
                      invalid:
                        parameter.value < parameter.minimum ||
                        parameter.value > parameter.maximum,
                      number: parameter.type === 'number',
                    }"
                  />
                </div>
              </div>
              <div
                v-if="parameter.type === 'options'"
                class="container-parameter"
              >
                <div>
                  <div class="h6">{{ parameter.nice_name }}</div>
                  <div class="body-1" style="color: #666666">
                    {{ parameter.description }}
                  </div>
                </div>
                <div style="text-align: right">
                  <select v-model="parameter.value" class="select-box">
                    <option
                      v-for="(option, optionindex) in parameter.options"
                      :key="optionindex"
                      class="select-box-option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="preview" class="body-1 dead-center">Preview only</div>
          </div>
          <div v-if="!preview" class="container-buttons">
            <BasicButton
              text="Get Template"
              :is_active="!template_is_requested && !inputIsInvalid"
              :is_loading="template_is_requested"
              @clicked="requestTemplateWithConfiguration"
            />
            <BasicButton
              :is_active="current_preview > 0"
              text="Download SVG"
              @clicked="downloadSVG"
            />
          </div>
        </div>
        <div class="container-right">
          <div v-if="!preview" class="container-previews">
            <LargePreview :src="preview_urls[current_preview]" />
            <MiniPreviews
              :urls="preview_urls"
              @update-preview-index="setCurrentPreviewIndex($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="disclaimer body-1 dead-center">
      Cutting Templates provides you these templates free of charge and "as is"
      for purposes of laser cutting, engraving, CNC, etc. The SVG files you
      generate are 100% free for both commercial and non-commercial usage. Do
      what you want, be kind, and share. Credits are appreciated.
    </div>
  </div>
</template>

<script>
import BasicButton from "../components/BasicButton.vue";
import HorizontalLine from "../components/HorizontalLine.vue";
import Notification from "../components/Notification.vue";
import TemplateTitleBar from "../components/TemplateTitleBar.vue";
import LargePreview from "../components/LargePreview.vue";
import MiniPreviews from "../components/MiniPreviews.vue";
import Swal from "sweetalert2";

export default {
  name: "TemplatePage",
  components: {
    BasicButton,
    Notification,
    HorizontalLine,
    LargePreview,
    MiniPreviews,
    TemplateTitleBar,
  },
  data() {
    return {
      template_found: false,
      template_name: "",
      template_description: "",
      parameters: [],
      template_is_requested: false,
      template_queue_id: "",
      preview_urls: [],
      current_preview: 1,
      experimental: false,
      preview: false,
      icon_close: require("../assets/close.svg"),
    };
  },
  computed: {
    inputIsInvalid() {
      for (let i = 0; i < this.parameters.length; i += 1) {
        if (
          this.parameters[i].value < this.parameters[i].minimum ||
          this.parameters[i].value > this.parameters[i].maximum
        ) {
          return true;
        }
      }
      return false;
    },
    previewImage() {
      return "https://cuttingtemplates.nl/" + this.template_name + ".png";
    },
  },
  created() {
    this.extractTemplateNameFromRoute();
    this.getAllParameters();
    this.initializeParameterValues();
  },
  methods: {
    gotoPrivacyPolicy() {
      window.location.href = "/privacy-policy";
    },
    setCurrentPreviewIndex(index) {
      this.current_preview = index;
    },
    async downloadSVG() {
      let link = document.createElement("a");
      link.download = this.template_name + ".svg";

      let response = await fetch(this.preview_urls[this.current_preview]);
      let svg = await response.blob();

      let blob = new Blob([svg], {
        type: "image/svg+xml",
      });
      link.href = URL.createObjectURL(blob);
      link.click();

      URL.revokeObjectURL(link.href);
    },
    extractTemplateNameFromRoute() {
      let split_route = this.$route.path.split("/");
      this.template_name = split_route[split_route.length - 1];
    },
    formatTemplateName() {
      let words = this.template_name.split("-");
      for (let i = 0; i < words.length; i += 1) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    },
    initializeParameterValues() {
      for (let i = 0; i < this.parameters.length; i += 1) {
        if ("default" in this.parameters[i]) {
          this.parameters[i].value = this.parameters[i].default;
        }
      }
    },
    async getAllParameters() {
      let response = await fetch(
        process.env.VUE_APP_API_HOST + "/template/" + this.template_name,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        window.location.href = "https://cuttingtemplates.com/404";
      }

      let data = await response.json();

      this.parameters = data.parameters;
      this.template_description = data.description;
      this.experimental = data.experimental;
      this.preview = data.preview;
      this.preview_urls.push(
        "https://cuttingtemplates.nl/" + this.template_name + ".png"
      );
      this.preview_urls.push(data.example);

      this.initializeParameterValues();
    },
    templateHasInputFile() {
      for (let i = 0; i < this.parameters.length; i += 1) {
        if (this.parameters[i].type === "file") {
          return true;
        }
      }
      return false;
    },
    isValidFileExtension(ext) {
      for (let i = 0; i < this.parameters.length; i += 1) {
        if (this.parameters[i].type === "file") {
          for (
            let ii = 0;
            ii < this.parameters[i].valid_extensions.length;
            ii += 1
          ) {
            if (this.parameters[i].valid_extensions[ii] === ext) {
              return true;
            }
          }
        }
      }
      return false;
    },
    getValidFileExtensions() {
      for (let i = 0; i < this.parameters.length; i += 1) {
        if (this.parameters[i].type === "file") {
          return this.parameters[i].valid_extensions;
        }
      }
      return [];
    },
    async requestTemplateWithConfiguration() {
      let request_url;
      let response;

      // Get request queue id
      request_url = process.env.VUE_APP_API_HOST + "/request-queue-id";
      response = await fetch(request_url);
      this.template_queue_id = await response.text();
      this.template_queue_id =
        this.template_queue_id + "-" + this.template_name;

      // IF DATA:
      let file_blob;
      let file_extension;
      if (this.templateHasInputFile()) {
        file_blob = document.getElementsByName("input_file")[0].files[0];

        if (!file_blob) {
          Swal.fire({
            icon: "error",
            title: "No file selected",
          });
          return;
        }

        // Validate data
        file_extension = file_blob.name.split(".");
        file_extension = file_extension[file_extension.length - 1];
        if (!this.isValidFileExtension(file_extension)) {
          Swal.fire({
            icon: "error",
            title: "Invalid file extension",
            text:
              "The following extensions are allowed:\n" +
              this.getValidFileExtensions(),
          });
          return;
        }

        if (file_blob.size > 2000000) {
          Swal.fire({
            icon: "error",
            title: "File too large",
            text: "The maximum file size is 2MB",
          });
          return;
        }

        // Send data
        request_url =
          process.env.VUE_APP_API_HOST +
          "/submit-asset/" +
          this.template_queue_id +
          "." +
          file_extension;

        response = await fetch(request_url, {
          method: "POST",
          body: file_blob,
        });
      }

      // Send build request
      request_url =
        process.env.VUE_APP_API_HOST +
        "/template/" +
        this.template_name +
        "/build?queue_id=" +
        this.template_queue_id;

      for (let i = 0; i < this.parameters.length; i += 1) {
        request_url += "&";

        if (
          this.parameters[i].type === "number" ||
          this.parameters[i].type === "checkbox"
        ) {
          request_url +=
            this.parameters[i].name + "=" + this.parameters[i].value;
        } else if (this.parameters[i].type === "options") {
          request_url +=
            this.parameters[i].name +
            "=" +
            this.parameters[i].options.indexOf(this.parameters[i].value);
        } else if (this.parameters[i].type === "file") {
          request_url +=
            this.parameters[i].name +
            "=" +
            this.template_queue_id +
            "." +
            file_extension;
        }
      }

      await fetch(request_url);
      this.template_is_requested = true;
      await this.pollIfTemplateBuildIsReady();
    },
    async pollIfTemplateBuildIsReady() {
      let request_url =
        process.env.VUE_APP_API_HOST +
        "/status?template_queue_id=" +
        this.template_queue_id;
      let response = await fetch(request_url);
      let data = await response.json();

      if (data.url !== "") {
        this.template_result_url = data.url;
        this.preview_urls.push(this.template_result_url);
        this.template_is_requested = false;
        this.current_preview = this.preview_urls.length - 1;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await this.pollIfTemplateBuildIsReady();
      }
    },
  },
};
</script>

<style scoped>
.disclaimer {
  font-style: italic;
  padding-top: 10em;
  color: var(--lighter-font-color);
}
.button {
  text-align: center;
  border-radius: 15px;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.container-all {
  padding: 0 2em;
}

@media (max-width: 500px) {
  .container-all {
    padding: 0 0.5em;
  }
}

.overlay-bg {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #00000055;
}

.container-left {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  gap: 40px;
}

.container-right {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  gap: 40px;
}

.swal2-confirm .mybutton {
  color: white !important;
  background-color: red !important;
  width: 100px !important;
  height: 100px !important;
}
.overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 4;
  margin-left: auto;
  margin-right: auto;
  width: 840px;
  height: 75%;
  padding: 40px;
  border-radius: 20px;
  background: var(--main-bg-color);
  border: 1px solid var(--main-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}
.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.container-titlebar {
  display: flex;
  flex-direction: row;
}

.container-content {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 4%;
}

@media (max-width: 500px) {
  .container-content {
    grid-template-columns: repeat(1, 100%);
  }
}

.parameters-panel {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding-top: 1em;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.container-parameter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 1em;
  gap: 20px;
}

.container-previews {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container-buttons {
  display: grid;
  grid-template-columns: repeat(2, 46%);
  gap: 8%;
}

.number {
  width: 80px;
  height: 20px;
  border-radius: 5px;
  color: var(--main-font-color);
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--secondary-bg-color);
  padding: 0.5em;
  text-align: left;
}

.select-box {
  width: 95px;
  border-radius: 5px;
  border: 1px solid var(--secondary-bg-color);
  padding: 0.5em;
}

.checkbox {
  width: 2em;
  height: 2em;
}

.invalid {
  border: 1px solid red;
  background: pink;
  color: #ff0000;
}

@media (max-width: 500px) {
  .container-content {
    flex-direction: column;
  }
}
</style>
