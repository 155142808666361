
<template>
  <hr class="divider" />
</template>

<script>
export default {
  name: "HorizontalLine",
};
</script>

<style scoped>
.divider {
  border: 0;
  border-top: 1px solid var(--secondary-bg-color);
  width: 100%;
}
</style>
