
<template>
  <div class="container-mini-previews">
    <div
      v-for="(url, index) in urls"
      :key="index"
      class="mini-preview"
      @click="$emit('updatePreviewIndex', index)"
    >
      <img :src="url" class="themed-svg-icon img-adjustments" id="svg-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LargePreview",
  components: {},
  props: ["urls"],
  emits: ["updatePreviewIndex"],
};
</script>

<style scoped>
.img-adjustments {
  border-radius: 0.5em;
  filter: var(--brightness-filter);
}

.img-adjustments:hover {
  filter: brightness(100%);
}

.container-mini-previews {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  gap: 3%;
}

@media (max-width: 500px) {
  .container-mini-previews {
    grid-template-columns: repeat(2, 48%);
    gap: 4%;
  }
}

.mini-preview {
  cursor: pointer;
  border-radius: 0.5em;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.mini-preview:hover {
  background: var(--secondary-bg-color);
}

.mini-preview img {
  max-width: 100%;
}
</style>
